import { FC } from 'react'
import { COLORS } from 'src/constants/colors'
import { numberWithCommas } from 'src/utils/numberFormat'
import { addThreeDotsToString } from 'src/utils/addThreeDotsToString'
import { ReactComponent as LoudspeakerSvg } from 'src/assets/svg/loudspeaker.svg'
import styles from './mostMentionsLegend.module.scss'
import { Legend } from '../interfaces/legend.interface'

const COLOR_PALETTE = [
  COLORS.malibu,
  COLORS.burningOrange,
  COLORS.periwinkle,
  COLORS.darkBlue,
  COLORS.pinkLace,
  COLORS.redOrange,
]

interface MostMentionsLegendProps {
  activeLegendItemIndex?: number
  legendData?: Legend[]
  onClickLegendItem: (itemIndex: number) => void
}

export const MostMentionsLegend: FC<MostMentionsLegendProps> = ({
  activeLegendItemIndex,
  legendData,
  onClickLegendItem,
}) => {
  return (
    <div className={styles.mostMentionsLegend}>
      {legendData?.map(({ name, value }, index) => {
        return (
          <div
            className={styles.item}
            key={`${name} - ${value}`}
            onClick={() => onClickLegendItem(index)}
          >
            <div
              className={styles.ball}
              style={{
                backgroundColor:
                  activeLegendItemIndex === index ||
                  activeLegendItemIndex === undefined
                    ? COLOR_PALETTE[index]
                    : COLORS.grey200,
              }}
            />

            <p className={styles.name}>{addThreeDotsToString(name, 25)}</p>

            <p className={styles.value}>{numberWithCommas(value)}</p>

            <LoudspeakerSvg className={styles.loudspeakerIcon} />
          </div>
        )
      })}
    </div>
  )
}
