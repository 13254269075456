import { FC } from 'react'
import classNames from 'classnames'
import en from 'src/constants/en'
import { useGetSuggestWordQuery } from 'src/reactQuery/useGetSuggestWordQuery'
import { QueryKey } from 'src/reactQuery/enums/queryKey.enum'
import Button from 'src/components/Button'
import { AutofillInput } from 'src/components/AutofillInput'
import styles from './addKeywordInput.module.scss'

interface AddKeywordInputProps {
  addNewKeyword: () => void
  currentKeywordInput: string
  keywordCount?: number
  setCurrentKeywordInput: (value: string) => void
}

export const AddKeywordInput: FC<AddKeywordInputProps> = ({
  currentKeywordInput,
  setCurrentKeywordInput,
  addNewKeyword,
  keywordCount,
}) => {
  const { data: suggestionData, handleSearch } = useGetSuggestWordQuery({
    queryKey: QueryKey.SUGGEST_WORD_CREATE_CAMPAIGN_KEYWORD,
  })

  const suggestedWord = suggestionData?.suggested_word || ''

  return (
    <div className={styles.addKeywordInputWrapper}>
      <div className={styles.labelWrapper}>
        <span className={styles.label}>{en.keyword}</span>

        {!!keywordCount && (
          <span className={styles.keywordCount}>
            {keywordCount}&nbsp;{en.keyWordsAdded}
          </span>
        )}
      </div>

      <div className={styles.addKeywordInputInner}>
        <AutofillInput
          addTag={() => addNewKeyword()}
          autoFillClassName={classNames(styles.addKeywordAutoFill)}
          autofilldWord={suggestedWord}
          fillKey={() => setCurrentKeywordInput(suggestedWord)}
          inputClassName={styles.addKeywordInput}
          isFullWidth
          isInputKeyword
          onChange={(value) => {
            setCurrentKeywordInput(value)

            if (value.length > 1) {
              handleSearch(value)
            }
          }}
          placeHolder={en.enterAKeyword}
          value={currentKeywordInput}
        />

        <Button className={styles.button} onClick={addNewKeyword} type="button">
          {en.add}
        </Button>
      </div>
    </div>
  )
}
