import { FC, useEffect, useRef, useState } from 'react'
import { faMinusCircle, faBullseye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Switch } from 'src/components/Switch'
import DynamicInput from './DynamicInput'
import styles from './targetPopup.module.scss'

interface TargetPopupProps {
  excludeValues: any
  isOpen: boolean
  onClose: () => void
  setExcludeValues: React.Dispatch<
    React.SetStateAction<{ [x: string]: string[] }>
  >
  setTargetValues: React.Dispatch<
    React.SetStateAction<{ [x: string]: string[] }>
  >
  targetName: string
  targetValues: any
}

const TargetPopup: FC<TargetPopupProps> = ({
  excludeValues,
  isOpen,
  onClose,
  targetValues,
  targetName,
  setExcludeValues,
  setTargetValues,
}) => {
  const [switchStates, setSwitchStates] = useState({
    excludes: false,
    targets: true,
  })
  const popupRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [onClose])

  const handleSwitchToggle = (name: 'excludes' | 'targets') => {
    setSwitchStates((prev) => ({
      ...prev,
      [name]: !prev[name],
      [name === 'excludes' ? 'targets' : 'excludes']: false,
    }))
    setTargetValues((prev) => ({ ...prev, [targetName]: [] }))
    setExcludeValues((prev) => ({ ...prev, [targetName]: [] }))
  }

  if (!isOpen) return null

  return (
    <div className={styles.popupContainer} ref={popupRef}>
      <div className={styles.inputLabelContainer}>
        <span className={styles.inputLabel}>
          <FontAwesomeIcon
            className={styles.sidebarMenuItemIcon}
            icon={faBullseye}
          />{' '}
          Enter Links to Target
        </span>
        <Switch
          isChecked={switchStates.targets}
          onChange={() => handleSwitchToggle('targets')}
        />
      </div>

      <div
        className={styles.listItems}
        style={{
          display: switchStates.targets ? 'block' : 'none',
          overflowY: targetValues[targetName]?.length > 3 ? 'auto' : 'hidden',
        }}
      >
        <DynamicInput
          name={targetName}
          setValues={setTargetValues}
          values={targetValues[targetName]}
        />
      </div>

      <div className={styles.separatorLine} />

      <div className={styles.inputLabelContainer}>
        <span className={styles.inputLabel}>
          <FontAwesomeIcon
            className={styles.sidebarMenuItemIcon}
            icon={faMinusCircle}
          />{' '}
          Enter Links to Exclude
        </span>
        <Switch
          isChecked={switchStates.excludes}
          onChange={() => handleSwitchToggle('excludes')}
        />
      </div>

      <div style={{ display: switchStates.excludes ? 'block' : 'none' }}>
        <DynamicInput
          name={targetName}
          setValues={setExcludeValues}
          values={excludeValues[targetName]}
        />
      </div>
    </div>
  )
}

export default TargetPopup
