import { FC, useState, useEffect, useMemo } from 'react'
import { EChartOption } from 'echarts'
import { getNumberOrZero } from 'src/utils/getNumberOrZero'
import { COLORS } from 'src/constants/colors'
import en from 'src/constants/en'
import { numberWithCommas } from 'src/utils/numberFormat'
import { useGetInformationTooltipsQuery } from 'src/reactQuery/useGetInformationTooltips'
import { SectionTitle } from 'src/components/SectionTitle'
import { ChartData } from './interfaces/chartData.interface'
import styles from './mostMentionsSection.module.scss'
import { Legend } from './interfaces/legend.interface'
import { MostMentionsLegend } from './MostMentionsLegend'
import { EChart } from '../EChart'

const COLOR_PALETTE = [
  COLORS.malibu,
  COLORS.burningOrange,
  COLORS.periwinkle,
  COLORS.darkBlue,
  COLORS.pinkLace,
  COLORS.redOrange,
]

const CHART_SIZE = 148
const SHOWN_COUNT = 5

interface MostMentionsSectionProps {
  activeItemIndex?: number
  chartData?: ChartData
  isLoading?: boolean
  updateActiveItemIndex: (index?: number) => void
}

export const MostMentionsSection: FC<MostMentionsSectionProps> = ({
  activeItemIndex,
  chartData,
  isLoading,
  updateActiveItemIndex,
}) => {
  const { data: informationTooltips } = useGetInformationTooltipsQuery()

  const [chartTitle, setChartTitle] = useState<string>()

  const [legendData, setLegendData] = useState<Legend[] | undefined>()

  const allMentionsCount = chartData?.reduce((total, mentionItem) => {
    return total + getNumberOrZero(mentionItem.mentions)
  }, 0)

  useEffect(() => {
    updateActiveItemIndex(undefined)

    if (!chartData) {
      setChartTitle('')
      setLegendData(undefined)
      return
    }

    if (allMentionsCount) {
      setChartTitle(allMentionsCount.toFixed(0))
    }

    const mappedData: Legend[] = chartData
      ?.slice(0, SHOWN_COUNT)
      ?.map((item) => {
        return {
          name: item.name || '',
          value: getNumberOrZero(item.mentions),
        }
      })

    const otherDataItem: Legend | undefined =
      chartData.length > SHOWN_COUNT
        ? chartData?.slice(SHOWN_COUNT, chartData.length)?.reduce(
            (total: Legend, item) => {
              return {
                ...total,
                value: total.value + getNumberOrZero(item.mentions),
              }
            },
            { name: en.others, value: 0 }
          )
        : undefined

    const data = [...mappedData]

    if (otherDataItem) {
      data.push(otherDataItem)
    }

    setLegendData(data)
  }, [chartData])

  const mappedColors = COLOR_PALETTE.map((activeColor, index) => {
    return activeItemIndex === index ? activeColor : COLORS.grey200
  })

  const chartOptions: EChartOption | undefined = useMemo(() => {
    const options: EChartOption<EChartOption.SeriesPie> = {
      color: activeItemIndex === undefined ? COLOR_PALETTE : mappedColors,
      legend: {
        show: false,
      },
      series: [
        {
          avoidLabelOverlap: true,
          data: legendData,
          emphasis: {
            // @ts-ignore-next-line
            scale: false,
          },
          height: CHART_SIZE,
          itemStyle: {
            borderColor: COLORS.ghostWhite,
            borderWidth: 5,
          },
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          left: 'center',
          radius: ['76%', '100%'],
          type: 'pie',
          width: CHART_SIZE,
        },
      ],
      tooltip: {},
    }

    return options
  }, [legendData, activeItemIndex])

  const onClickLegendItem = (itemIndex: number) => {
    if (activeItemIndex === itemIndex) {
      updateActiveItemIndex(undefined)
      setChartTitle(allMentionsCount?.toFixed(0))
    } else {
      updateActiveItemIndex(itemIndex)
      setChartTitle(legendData?.[itemIndex]?.value?.toFixed(0))
    }
  }

  return (
    <div className={styles.mostMentionsSection}>
      <SectionTitle
        isLoading={isLoading}
        title={en.mostMentions}
        tooltipContent={informationTooltips?.mostMentions}
      />

      <div className={styles.chartContainer}>
        <div className={styles.chartInfo}>
          <div className={styles.chartTitle}>
            {chartTitle &&
              (chartTitle.includes('%')
                ? chartTitle
                : numberWithCommas(parseFloat(chartTitle)))}
          </div>

          <div className={styles.chartSubTitle}>{en.mentions}</div>
        </div>

        {chartOptions ? (
          <EChart
            onMouseOut={() =>
              setChartTitle(
                activeItemIndex !== undefined
                  ? legendData?.[activeItemIndex]?.value?.toFixed(0)
                  : allMentionsCount?.toFixed(0)
              )
            }
            onMouseOver={(event) =>
              setChartTitle(`${event.percent?.toFixed(0)}%`)
            }
            options={chartOptions}
          />
        ) : null}
      </div>

      <MostMentionsLegend
        activeLegendItemIndex={activeItemIndex}
        legendData={legendData}
        onClickLegendItem={onClickLegendItem}
      />
    </div>
  )
}
