import { FC, useEffect, useRef } from 'react'
import { faInfoCircle, faBullseye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PlacesMap from 'src/components/GoogleMapsPlaceIDFinder'
import styles from './googleTripadvisorPopup.module.scss'
import DynamicInput from '../DynamicInput'

interface GoogleTripadvisorPopupProps {
  isOpen: boolean
  onClose: () => void
  setTargetValues: React.Dispatch<
    React.SetStateAction<{ [x: string]: string[] }>
  >
  targetName: string
  targetValues: any
}

const GoogleTripadvisorPopup: FC<GoogleTripadvisorPopupProps> = ({
  isOpen,
  onClose,
  targetName,
  targetValues,
  setTargetValues,
}) => {
  const popupRef = useRef<HTMLDivElement>(null)

  const nestedRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const node = event.target as HTMLElement

      if (
        popupRef.current &&
        !popupRef.current.contains(node) &&
        !node.closest('.pac-container')
      ) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [onClose])

  if (!isOpen) return null

  return (
    <div className={styles.popupContainer} ref={popupRef}>
      <div className={styles.inputLabelContainer}>
        <span className={styles.inputLabel}>
          <FontAwesomeIcon
            className={styles.sidebarMenuItemIcon}
            icon={faBullseye}
          />
          {targetName === 'Google Reviews'
            ? 'Enter Place ID to Target'
            : 'Search For Your Place to Target'}
        </span>
      </div>

      <div className={styles.listItems}>
        <DynamicInput
          name={targetName}
          nestedRef={nestedRef}
          setValues={setTargetValues}
          values={targetValues[targetName]}
        />
      </div>

      {targetName === 'Google Reviews' && (
        <PlacesMap
          placeId={
            targetValues[targetName]?.[targetValues[targetName].length - 1]
          }
        />
      )}

      <div className={styles.infoBox}>
        <FontAwesomeIcon className={styles.infoIcon} icon={faInfoCircle} />
        {targetName === 'Tripadvisor' ? (
          'Search and select your venue from the options'
        ) : (
          <div>
            Search for your venue on map above and copy your Place ID. Suggested
            format:
            <span className={styles.formatString}>
              {' '}
              ChIJu7yy4DlzREARbpVJRWLU1y0
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default GoogleTripadvisorPopup
