import classNames from 'classnames'
import { FC, useEffect, useState, FormEvent, MouseEvent } from 'react'
import { toast } from 'react-toastify'
import Button from 'src/components/Button'
import PasswordVisibilityButton from 'src/components/PasswordVisibilityButton'
import en from 'src/constants/en'
import { MINIMUM_PASSWORD_LENGTH } from 'src/constants/password'
import { GetSelfUser } from 'src/services/napi/getSelf/interfaces/getSelf.interface'
import { UpdateSelfParams } from 'src/services/napi/updateSelf/interfaces/updateSelf.interface'
import { UpdateSelfPasswordParams } from 'src/services/napi/updateSelfPassword/interfaces/updateSelfPassword.interface'
import styles from './yourAccount.module.scss'

interface YourAccountProps {
  handleUpdatePassword: (params: UpdateSelfPasswordParams) => Promise<boolean>
  handleUpdateUser: (params: UpdateSelfParams) => Promise<boolean>
  userData?: GetSelfUser
}

const YourAccount: FC<YourAccountProps> = ({
  userData,
  handleUpdateUser,
  handleUpdatePassword,
}) => {
  const [firstName, setFirstName] = useState<string>('')

  const [lastName, setLastName] = useState<string>('')

  const [email, setEmail] = useState<string>('')

  const [company, setCompany] = useState<string>('')

  const [oldPassword, setOldPassword] = useState<string>('')

  const [newPassword, setNewPassword] = useState<string>('')

  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const [oldPasswordError, setOldPasswordError] = useState<boolean>(false)

  const [newPasswordError, setNewPasswordError] = useState<boolean>(false)

  const [confirmPasswordError, setConfirmPasswordError] =
    useState<boolean>(false)

  const [oldPasswordVisibility, setOldPasswordVisibility] =
    useState<boolean>(false)

  const [newPasswordVisibility, setNewPasswordVisibility] =
    useState<boolean>(false)

  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState<boolean>(false)

  const [editMode, setEditMode] = useState(false)

  const resetPasswordFields = () => {
    setOldPassword('')
    setNewPassword('')
    setConfirmPassword('')
  }

  const resetPasswordErrors = () => {
    setOldPasswordError(false)
    setNewPasswordError(false)
    setConfirmPasswordError(false)
  }

  const resetPasswordVisibilities = () => {
    setOldPasswordVisibility(false)
    setNewPasswordVisibility(false)
    setConfirmPasswordVisibility(false)
  }

  const setDataFromUser = () => {
    setFirstName(userData?.firstName || '')
    setLastName(userData?.lastName || '')
    setEmail(userData?.email || '')
    setCompany(userData?.metadata?.companyName || '')

    resetPasswordFields()
    resetPasswordErrors()
    resetPasswordVisibilities()
  }

  const hasUserDataChanged = (): boolean => {
    return (
      email !== userData?.email ||
      firstName !== userData?.firstName ||
      lastName !== userData?.lastName ||
      company !== userData?.metadata?.companyName
    )
  }

  const hasEnteredNewPassword = (): boolean => {
    return !!newPassword || !!confirmPassword
  }

  const arePasswordsValid = (): boolean => {
    resetPasswordErrors()

    if (!newPassword && !confirmPassword) {
      return false
    }

    if (!oldPassword || !newPassword || !confirmPassword) {
      toast.error(en.PASSWORD_ERROR_NOT_FILLED_ALL_FIELDS)

      if (!oldPassword) {
        setOldPasswordError(true)
      }

      if (!newPassword) {
        setNewPasswordError(true)
      }

      if (!confirmPassword) {
        setConfirmPasswordError(true)
      }

      return false
    }

    if (newPassword !== confirmPassword) {
      toast.error(en.PASSWORD_ERROR_NOT_MATCHING)

      setNewPasswordError(true)
      setConfirmPasswordError(true)

      return false
    }

    if (newPassword.length < MINIMUM_PASSWORD_LENGTH) {
      toast.error(en.PASSWORD_ERROR_TOO_SHORT)

      setNewPasswordError(true)
      setConfirmPasswordError(true)

      return false
    }

    return true
  }

  async function handleSubmitAccountSettings(
    event: FormEvent<HTMLFormElement>
  ): Promise<void> {
    event.preventDefault()

    if (hasEnteredNewPassword()) {
      if (!arePasswordsValid()) {
        return
      }

      const success = await handleUpdatePassword({ newPassword, oldPassword })

      if (!success) {
        setOldPasswordError(true)
        setNewPasswordError(true)
        setConfirmPasswordError(true)

        return
      }
    }

    if (hasUserDataChanged()) {
      const success = await handleUpdateUser({
        email,
        firstName,
        lastName,
        metadata: {
          companyName: company,
        },
      })

      if (!success) {
        return
      }
    }

    resetPasswordFields()
    resetPasswordErrors()
    resetPasswordVisibilities()

    setEditMode(false)
  }

  function handleCancelEditing(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault()

    setDataFromUser()

    setEditMode(false)
  }

  useEffect(() => {
    setDataFromUser()
  }, [userData])

  return (
    <form className={styles.form} onSubmit={handleSubmitAccountSettings}>
      <div className={styles.card}>
        <div className={styles.formRow}>
          <label htmlFor="firstName">{en.firstName}</label>

          <div className={styles.inputWrapper}>
            <input
              className={editMode ? styles.enabled : styles.disabled}
              id="firstName"
              onChange={(e) => setFirstName(e.target.value)}
              readOnly={!editMode}
              type="text"
              value={firstName}
            />
          </div>
        </div>

        <hr />

        <div className={styles.formRow}>
          <label htmlFor="lastName">{en.lastName}</label>

          <div className={styles.inputWrapper}>
            <input
              className={editMode ? styles.enabled : styles.disabled}
              id="lastName"
              onChange={(e) => setLastName(e.target.value)}
              readOnly={!editMode}
              type="text"
              value={lastName}
            />
          </div>
        </div>

        <hr />

        <div className={styles.formRow}>
          <label htmlFor="staticEmail">{en.email}</label>

          <div className={styles.inputWrapper}>
            <input
              className={editMode ? styles.enabled : styles.disabled}
              id="staticEmail"
              onChange={(e) => setEmail(e.target.value)}
              readOnly={!editMode}
              type="text"
              value={email}
            />
          </div>
        </div>

        <hr />

        <div className={styles.formRow}>
          <label htmlFor="company">{en.company}</label>

          <div className={styles.inputWrapper}>
            <input
              className={editMode ? styles.enabled : styles.disabled}
              id="company"
              onChange={(e) => setCompany(e.target.value)}
              readOnly={!editMode}
              type="text"
              value={company}
            />
          </div>
        </div>

        <hr />

        {editMode ? (
          <>
            <div className={styles.formRow}>
              <label htmlFor="oldPassword">{en.oldPassword}</label>

              <div className={styles.inputWrapper}>
                <input
                  className={classNames(
                    styles.enabled,
                    oldPasswordError && styles.error
                  )}
                  id="oldPassword"
                  onChange={(e) => setOldPassword(e.target.value)}
                  placeholder="Old Password"
                  type={oldPasswordVisibility ? 'text' : 'password'}
                  value={oldPassword}
                />

                <PasswordVisibilityButton
                  className={styles.visibilityButton}
                  toggleVisibility={(val) => setOldPasswordVisibility(val)}
                  visibility={oldPasswordVisibility}
                />
              </div>
            </div>

            <hr />

            <div className={styles.formRow}>
              <label htmlFor="newPassword">{en.newPassword}</label>

              <div className={styles.inputWrapper}>
                <input
                  className={classNames(
                    styles.enabled,
                    newPasswordError && styles.error
                  )}
                  id="newPassword"
                  onChange={(e) => setNewPassword(e.target.value)}
                  placeholder="New Password"
                  type={newPasswordVisibility ? 'text' : 'password'}
                  value={newPassword}
                />

                <PasswordVisibilityButton
                  className={styles.visibilityButton}
                  toggleVisibility={(val) => setNewPasswordVisibility(val)}
                  visibility={newPasswordVisibility}
                />
              </div>
            </div>

            <hr />

            <div className={styles.formRow}>
              <label htmlFor="confirmPassword">{en.confirmPassword}</label>

              <div className={styles.inputWrapper}>
                <input
                  className={classNames(
                    styles.enabled,
                    confirmPasswordError && styles.error
                  )}
                  id="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm Password"
                  type={confirmPasswordVisibility ? 'text' : 'password'}
                  value={confirmPassword}
                />

                <PasswordVisibilityButton
                  className={styles.visibilityButton}
                  toggleVisibility={(val) => setConfirmPasswordVisibility(val)}
                  visibility={confirmPasswordVisibility}
                />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.formRow}>
            <label htmlFor="passwordId">{en.password}</label>

            <div className={styles.inputWrapper}>
              <input
                className={styles.disabled}
                id="passwordId"
                readOnly
                type="password"
                value="********"
              />
            </div>
          </div>
        )}
      </div>

      {editMode ? (
        <div className={styles.buttonsRow}>
          <Button
            background="transparent"
            onClick={(e) => handleCancelEditing(e)}
            type="button"
          >
            {en.cancel}
          </Button>

          <Button background="malibu" type="submit">
            {en.save}
          </Button>
        </div>
      ) : (
        <div className={styles.buttonsRow}>
          <Button
            background="malibu"
            onClick={() => setEditMode(true)}
            type="button"
          >
            {en.edit}
          </Button>
        </div>
      )}
    </form>
  )
}

export default YourAccount
